import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {
    MDBBtn,
    MDBInput,
    MDBContainer,
    MDBCard,
    MDBCardBody
} from 'mdb-react-ui-kit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt, faHomeAlt } from '@fortawesome/free-solid-svg-icons';
import {
    faCheck,
    faTimes,
    faInfoCircle,
    faRefresh,
    faUpload,
    faEdit
  } from '@fortawesome/free-solid-svg-icons'

const CorrectionForm = () => {
    const server_app_api_php = window.SERVER_APP_API_PHP;
    let [handleSubmitBtn, setHandleSubmitBtn] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [pdfUrl, setPdfUrl] = useState(null);

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        country: '',
        gender: '',
        email: '',
        phone: '',
        category: ''

    });

    const isFormValid = () => {
        return Object.values(formData).every((value) => value !== '' && value !== null);
      };

    const countries = [
        "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina",
        "Armenia", "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados",
        "Belarus", "Belgium", "Belize", "Benin", "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana",
        "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cabo Verde", "Cambodia", "Cameroon", "Canada",
        "Central African Republic", "Chad", "Chile", "China", "Colombia", "Comoros", "Congo, Democratic Republic of the",
        "Congo, Republic of the", "Costa Rica", "Côte d'Ivoire", "Croatia", "Cuba", "Cyprus", "Czech Republic", "Denmark",
        "Djibouti", "Dominica", "Dominican Republic", "East Timor", "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea",
        "Eritrea", "Estonia", "Eswatini", "Ethiopia", "Fiji", "Finland", "France", "Gabon", "Gambia", "Georgia", "Germany",
        "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", "Guyana", "Haiti", "Honduras", "Hungary",
        "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", "Israel", "Italy", "Jamaica", "Japan", "Jordan",
        "Kazakhstan", "Kenya", "Kiribati", "Korea, North", "Korea, South", "Kosovo", "Kuwait", "Kyrgyzstan", "Laos",
        "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Madagascar",
        "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", "Mauritania", "Mauritius", "Mexico",
        "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco", "Mozambique", "Myanmar", "Namibia",
        "Nauru", "Nepal", "Netherlands", "New Zealand", "Nicaragua", "Niger", "Nigeria", "North Macedonia", "Norway",
        "Oman", "Pakistan", "Palau", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland",
        "Portugal", "Qatar", "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines",
        "Samoa", "San Marino", "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone",
        "Singapore", "Slovakia", "Slovenia", "Solomon Islands", "Somalia", "South Africa", "South Sudan", "Spain", "Sri Lanka",
        "Sudan", "Suriname", "Sweden", "Switzerland", "Syria", "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Togo", "Tonga",
        "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates",
        "United Kingdom", "United States", "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela", "Vietnam",
        "Yemen", "Zambia", "Zimbabwe"
      ];

      useEffect(() => {
        localStorage.removeItem('userType')
        localStorage.removeItem('userName')
        localStorage.setItem('login', JSON.stringify('No'))
    }, [])

    const [isDataFetched, setIsDataFetched] = useState(false);
    const [searchData, setSearchData] = useState({ emailOrPhone: '' });
    const [matchedData, setMatchedData] = useState(null);

    // Handle search input
    const handleSearchInputChange = (e) => {
        const { name, value } = e.target;
        setSearchData((prevState) => ({ ...prevState, [name]: value }));
    };

    useEffect(() => {
        setIsSubmitted(false);
        const formDataSearch = new FormData();
  formDataSearch.append('apiType', 'searchApplicant');
  formDataSearch.append('emailOrPhone', searchData.emailOrPhone);
  // Send the PDF to your server
  axios.post(`${server_app_api_php}/server/index.php`, formDataSearch, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  .then(response => {
    //console.log('Data Received From Server', response.data);
    if(response.data.success) {                        
        setFormData(response.data.applicant);
        setIsDataFetched(true);
    }
    else{
        setFormData({
            firstName: '',
            lastName: '',
            country: '',
            gender: '',
            email: '',
            phone: '',
            category: ''
        });
        setIsDataFetched(false);
    }
  })
  .catch(error => {
    //console.error('Error fetching applicant data:', error);
    alert('Error : Check Your Internet Connection Or Change Your Internet Source');
  });
    }, [searchData]);

 
    // Trigger constant axios call to check if email or phone matches
   
    // Handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleUpdateSubmit = () => {
        setHandleSubmitBtn(true);

        const { firstName, lastName, country, gender, category, email, phone } = formData;
  
    const doc = new jsPDF();
    const img = new Image();
    img.src = require('./images/summit.jpg'); // Ensure this path is correct
  
    img.onload = () => {
      // Set background image
      doc.addImage(img, 'JPEG', 0, 0, 210, 297); // Assuming A4 size template
  
      // Set the font family, size, and color to match the design
      doc.setFont('Helvetica', 'bold');
      doc.setFontSize(30);
      doc.setTextColor(23, 44, 122); // Navy blue color
  
      // Name
      doc.text(105, 150, `${firstName} ${lastName}`, { align: 'center' });
  
      // Gender Pronouns
      doc.setFont('Helvetica', 'normal');
      doc.setFontSize(25);
      doc.text(105, 160, `${gender}`, { align: 'center' });
  
      // Country
      doc.text(105, 170, `${country}`, { align: 'center' });
  
      // Category (e.g., Delegate)
      doc.setFont('Helvetica', 'bold');
      doc.setFontSize(35);
      doc.setTextColor(255, 255, 255); // White color for category
      doc.text(108, 286, `${category.toUpperCase()}`, { align: 'center' });
  
      // Add passport size photo
      //const reader = new FileReader();
      //reader.onload = function(event) {
        // Adjust dimensions and position to fit the designated space
        //doc.addImage(event.target.result, 'JPEG', 80, 180, 50, 50); // Adjust these coordinates and size to fit your design
        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
  
        const pdfOutput = doc.output('blob');
    

          const updateFormData = new FormData();
        Object.keys(formData).forEach((key) => {
            updateFormData.append(key, formData[key]);
        });
        updateFormData.append('apiType', 'updateApplicant');
        updateFormData.append('file', pdfOutput, `Edited Summit ID -   ${lastName} ${firstName}.pdf`);
        //console.log('data am sending', formData);
        //const formData = new FormData();
        //formData.append('apiType', 'updateApplicant');
        //formData.append('emailOrPhone', searchData.emailOrPhone);
        // Send the PDF to your server
        axios.post(`${server_app_api_php}/server/index.php`, updateFormData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          //console.log('Data Received From Server', response.data);
          if(response.data.success) {        
            alert('Details updated successfully!');
            setPdfUrl(pdfUrl);
            setHandleSubmitBtn(false);
            setIsSubmitted(true);
          }
          else{
            alert('No changes detected or update failed.');
            setHandleSubmitBtn(false);
            setIsSubmitted(false);
          }
        })
        .catch(error => {
            //console.error('Error updating data:', error);
            alert('Error : Check Your Internet Connection Or Change Your Internet Source');
            setHandleSubmitBtn(false);
            setIsSubmitted(false);
        });

    };
  
    img.onerror = function(error) {
      //console.error('Error loading template image: ', error);
    };

    };

    const headerStyle = {
        backgroundColor: '#002D62', // Dark blue
        color: '#ffffff', // White text
        padding: '10px 20px',
        position: 'fixed',
        top: '0',
        width: '100%',
        zIndex: '1000',
      };
    const socialIconStyle = {
        marginLeft: '15px',
        color: '#ffffff',
        fontSize: '1.2em',
      };

      const updateButtonStyle = {
        backgroundColor: '#FF8800', // Dark blue
        color: '#ffffff', // White text
        borderRadius: '5px',
        fontWeight: 'bold',
        padding: '10px 20px',
        border: 'none',
      };

    return (
        <>
          <div hidden={!handleSubmitBtn}>
       
       <div class="loader d-flex justify-content-center">
     <div class="spinner-border red-text" style={{width: '10rem', height: '10rem'}} role="status">
         <span class=''>Update...</span>
       </div>
     </div>
     </div>

         <header style={headerStyle} className=''>
        <div className="d-flex justify-content-between align-items-center">
          <div>
         
            <a href="/" className="text-white">
              <FontAwesomeIcon icon={faHomeAlt} /> Home
            </a>
            <a href="Login" className="text-white ml-3">
              <FontAwesomeIcon icon={faSignInAlt} /> Login
            </a>
          </div>
          <div>
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" style={socialIconStyle}>
            <span className='fa fa-facebook'></span>
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" style={socialIconStyle}>
            <span className='fa fa-twitter'></span>
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" style={socialIconStyle}>
              
              <span className='fa fa-instagram'></span>
            </a>
          </div>
        </div>
      </header>
        <MDBContainer>
            <MDBCard>
                <MDBCardBody className='text-left'>
                    <h5 className='mt-5 text-uppercase text-center'>Find Your Information</h5>
                    <label>Enter Phone or Email</label>
                    <MDBInput
                        label=""
                        name="emailOrPhone"
                        value={searchData.emailOrPhone}
                        onChange={handleSearchInputChange}
                    />

                    {isDataFetched && (
                        <>
                            <h5 className='form-header mt-5 text-uppercase mdb-color darken-3'>Update Your Information</h5>
                            <label>First Name</label>
                            <MDBInput
                                label=""
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleInputChange}
                                className='mb-3'
                            />
                            <label>Last Name</label>
                            <MDBInput
                                label=""
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleInputChange}
                                className='mb-3'
                            />
                           
                           <div className='text-left'>
                            <label>Country</label>
                            <select
                                name="country"
                                id="country"
                                value={formData.country} // Pre-populate from database
                                onChange={handleInputChange}
                                className="mb-3 form-select"
                            >
                                <option value="" disabled>Select Country</option>
                                {countries.map((country, index) => (
                                    <option key={index} value={country}>
                                        {country}
                                        </option>
                                    ))}
                            </select>
                            </div>

                            <div className='text-left'>
                            <label>Pronouns</label>
                            <select
                                name="gender"
                                id="gender"
                                value={formData.gender} // Pre-populate from database
                                onChange={handleInputChange}
                                className="mb-3 form-select"
                            >
                                <option value="" >Select Pronoun</option>
                                <option>He/Him/His</option>
                                <option>She/Her/Hers</option>
                            </select>
                            </div>

                            <label>Email</label>
                            <MDBInput
                                label=""
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                className='mb-3'
                            />
                            <label>Phone</label>
                            <MDBInput
                                label=""
                                name="phone"
                                value={formData.phone}
                                onChange={handleInputChange}
                                className='mb-3'
                            />
                          <div className='text-left'>
                           <label>Category</label>
                            <select
                                name="category"
                                id="category"
                                value={formData.category} // Pre-populate from database
                                onChange={handleInputChange}
                                className="mb-3 form-select"
                            >
                    <option value="" >Select Category</option>
                    <option value="Delegate">Delegate</option>
                    <option value="Speaker">Speaker</option>
                    <option value="Performer">Performer</option>
                    <option value="Counsellor">Counsellor</option>
                    <option value="Organiser">Organiser</option>
                    <option value="Delegate Speaker">Delegate Speaker</option>
                    <option value="Volunteer">Volunteer</option>
                            </select>
                            </div>

                   

<div className='row'>

<div className='col-md-12'>
                {isSubmitted && (
                <button
                  type="button"
                  className="btn btn-success w-100 mb-5"
                  data-toggle="modal"
                  data-target="#viewIdModal"
                >
                  View ID
                  <span className='fa fa-eye ml-3'></span>
                </button>
              )}
              </div>

                    <div className='col-md-12'>
             <button className="btn w-100 mb-3" onClick={handleUpdateSubmit} style={updateButtonStyle} 
                            disabled={!isFormValid() || handleSubmitBtn}
                            >Update
                            <FontAwesomeIcon icon={faEdit} className='ml-3' />
                            </button>
                </div>
                
                
                </div>

                        </>
                    )}

<div
        className="modal fade"
        id="viewIdModal"
        tabIndex="-1"
        aria-labelledby="viewIdModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="viewIdModalLabel">Your Edited Summit ID</h5>
              <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {pdfUrl ? (
                <embed src={pdfUrl} type="application/pdf" width="100%" height="500px" />
              ) : (
                <p>Loading...</p>
              )}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

                </MDBCardBody>
            </MDBCard>
        </MDBContainer>


</>
    );
};

export default CorrectionForm;
