import logo from './logo.svg';
import './App.css';
import { Routes, Route } from 'react-router-dom'

import Missing from './pages/Missing'
import Layout from './pages/Layout'
import Dashboard from './pages/Dashboard'
import ForgotPassword from './pages/ForgotPassword'
import Login from './pages/Login'
import CorrectionForm from './pages/CorrectionForm'
import ApplicationForm from './pages/ApplicationForm'

function App () {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route path='*' element={<Missing />} />
        <Route path='/' element={<ApplicationForm />} />
        <Route path='/Login' element={<Login />} />
        <Route path='/Dashboard' element={<Dashboard />} />
        <Route path='/ForgotPassword' element={<ForgotPassword />} />
        <Route path='/Apply' element={<ApplicationForm />} />
        <Route path='/Corrections' element={<CorrectionForm />} />
      </Route>
    </Routes>
  )
}

export default App;
