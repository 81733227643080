import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faCalendarAlt, faBriefcase, faUsers, faMoneyBillAlt, faChurch, faGraduationCap, faComments, faUserTie, faUser, faTachometerAlt, faCog, faEye, faEnvelope, faCoins, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import './Header.css'; // Make sure to create and import this CSS file

const Header = () => {
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    const toggleMobileMenu = () => {
        setShowMobileMenu(!showMobileMenu);
    }

    let userType = localStorage.getItem('userType');

    return (
        <div className="dashboard" >
            <nav id="navbar" className="navbar navbar-expand-lg navbar-light bg-white fixed-top">
                <div className="navbar-brand">Real Estate</div>
                <button className="navbar-toggler" type="button" onClick={toggleMobileMenu}>
                    <span className="navbar-toggler-icon">
                        {showMobileMenu ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faBars} />}
                    </span>
                </button>
                <div className={`collapse navbar-collapse ${showMobileMenu ? 'show' : ''}`}>
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item">
                            <Link className="nav-link" to="/Dashboard"><FontAwesomeIcon icon={faTachometerAlt} /> Dashboard</Link>
                        </li>
                        { userType != 'Customer' && userType != 'Tenant' && (
                            <>
                        <li className="nav-item dropdown" hidden = {userType != 'Admin'}>
                            <Link className="nav-link dropdown-toggle" to="" id="systemSettingsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <FontAwesomeIcon icon={faCog} /> System Settings
                            </Link>
                            <div className="dropdown-menu" aria-labelledby="systemSettingsDropdown">
                                <Link className="dropdown-item" to="/CreateCompanyForm">Register Company</Link>
                                <Link className="dropdown-item" to="/CreateBusinessLocationForm">Register Business Location</Link>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" to="" id="businessSettingsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <FontAwesomeIcon icon={faCog} /> Business Settings
                            </Link>
                            <div className="dropdown-menu" aria-labelledby="businessSettingsDropdown">
                                <Link className="dropdown-item" to="/UploadPlotsForm">Register Plots</Link>
                                <Link className="dropdown-item" to="/UploadHousesForm">Register Houses</Link>
                                <Link className="dropdown-item" to="/UploadUsersForm">Register Users</Link>
                                <Link className="dropdown-item" to="/UploadPlotsCustomersForm">Register Plots Customers</Link>
                                <Link className="dropdown-item" to="/UploadTenantsForm">Register Tenants</Link>
                                <Link className="dropdown-item" to="/CreateExpenseForm">Create Expenses</Link>
                                <Link className="dropdown-item" to="/UploadRentalsExpenseForm">Register Rentals Expense</Link>
                                <Link className="dropdown-item" to="/UploadPlotsExpenseForm">Register Plots Expense</Link>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" to="" id="systemReportsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <FontAwesomeIcon icon={faEye} /> System Reports
                            </Link>
                            <div className="dropdown-menu" aria-labelledby="systemReportsDropdown">
                                <Link className="dropdown-item" to="/ViewPlots">View Plots</Link>
                                <Link className="dropdown-item" to="/ViewHouses">View Houses</Link>
                                <Link className="dropdown-item" to="" hidden>View Users</Link>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" to="" id="businessReportsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <FontAwesomeIcon icon={faEye} /> Clients Statements
                            </Link>
                            <div className="dropdown-menu" aria-labelledby="businessReportsDropdown">
                                <Link className="dropdown-item" to="/ViewTenants">House Tenants Statements</Link>
                                <Link className="dropdown-item" to="/ViewPlotsCustomers">Plot Customers Statements</Link>
                                <Link className="dropdown-item" to="/ViewPlotsCustomersUptodatePayers" >View UptoDate Plot Customers</Link>
                                <Link className="dropdown-item" to="/ViewPlotsCustomersLatePayers" >View Late Plot Customers</Link>
                                <Link className="dropdown-item" to="/ViewPlotsCustomersNHEF">NHEF</Link>
                            </div>
                        </li>
                        <li className="nav-item dropdown" hidden>
                            <Link className="nav-link dropdown-toggle" to="" id="messagesDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <FontAwesomeIcon icon={faEnvelope} /> Messages
                            </Link>
                            <div className="dropdown-menu" aria-labelledby="messagesDropdown">
                                <Link className="dropdown-item" to="">Inbox</Link>
                                <Link className="dropdown-item" to="">Sent</Link>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" to="" id="makePaymentsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <FontAwesomeIcon icon={faCoins} /> Pay For Client
                            </Link>
                            <div className="dropdown-menu" aria-labelledby="makePaymentsDropdown">
                                <Link className="dropdown-item" to="/MakePaymentForClientPlotsForm">Pay For Plot</Link>
                                <Link className="dropdown-item" to="/MakePaymentForClientRentalsForm">Pay Rentals</Link>
                            </div>
                        </li>
                        <li className="nav-item dropdown" hidden>
                            <Link className="nav-link dropdown-toggle" to="" id="makePaymentsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <FontAwesomeIcon icon={faCoins} /> Pay
                            </Link>
                            <div className="dropdown-menu" aria-labelledby="makePaymentsDropdown">
                                <Link className="dropdown-item" to="">Pay For Plot</Link>
                                <Link className="dropdown-item" to="">Pay Rentals</Link>
                            </div>
                        </li>
                        </>
                        )}
                        {(<></>)}


                        <li className="nav-item">
                            <Link className="nav-link " to="/"><span className='red-text'><FontAwesomeIcon icon={faSignOutAlt} /> </span> Logout</Link>
                        </li>
                    </ul>
                </div>
            </nav>
            {/* Dashboard content goes here */}
        </div>
    );
}

export default Header;
