import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { AuthProvider } from './context/AuthProvider'

//fpdi_tcpdf
//window.SERVER_APP_API_PHP = 'https://www.youthsdgsummit.kanchtech.com/' //server files
window.SERVER_APP_API_PHP = 'https://www.orthopraxyministries.com/youth-summit'
//window.SERVER_APP_API_PHP = 'http://localhost/react/youth-sdg-summit/src' //server files
//C:\xampp\htdocs\react\youth-sdg-summit\src\server

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path='/*' element={<App />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
