import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { CSVLink } from 'react-csv';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useReactToPrint } from 'react-to-print';
import { PDFDocument } from 'pdf-lib';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import "datatables.net-dt/js/dataTables.dataTables";
//import "datatables.net-dt/css/jquery.dataTables.min.css";
import Header from './Header';
import logo from './images/icons/logo.JPG'; // Import your image here

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt, faHomeAlt } from '@fortawesome/free-solid-svg-icons';
import {
  faCheck,
  faTimes,
  faInfoCircle,
  faRefresh,
  faUpload,
  faEdit,
  faUsers,
  faSignOutAlt,
  faRegistered
} from '@fortawesome/free-solid-svg-icons'
import {
  MDBBtn,
  MDBInput,
  MDBContainer,
  MDBCard,
  MDBCardBody
} from 'mdb-react-ui-kit';
import { useNavigate } from 'react-router-dom'

const Dashboard = () => {
  let navigate = useNavigate()
  let loggedIn = localStorage.getItem('login')
  loggedIn = JSON.parse(loggedIn);
if(loggedIn === "No"){
  //alert("Error: Session Expired");
  navigate('/Login')
}


  const server_app_api_php = window.SERVER_APP_API_PHP;
  let [handleSubmitBtn, setHandleSubmitBtn] = useState(false);
  const [viewUsers, setViewUsers] = useState([]);
  const [viewApplicants, setViewApplicants] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    userType: ''

});

const isFormValid = () => {
  return Object.values(formData).every((value) => value !== '' && value !== null);
};

  let userType = localStorage.getItem('userType')
  userType = JSON.parse(userType)

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
};

  const handleSubmit = () => {
    setHandleSubmitBtn(true);

    const updateFormData = new FormData();
    Object.keys(formData).forEach((key) => {
        updateFormData.append(key, formData[key]);
    });
    updateFormData.append('apiType', 'registerUsers');
    axios.post(`${server_app_api_php}/server/index.php`, updateFormData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      //console.log('Data Received From Server', response.data);
      if(response.data.success) {  
        setHandleSubmitBtn(false);
        alert(response.data.message);
      }
      else{
        alert(response.data.message);
        //alert('Failed To Login.');
        setHandleSubmitBtn(false);
      }
    })
    .catch(error => {
        //console.error('Error Login');
        alert('Error : Check Your Internet Connection Or Change Your Internet Source');
        setHandleSubmitBtn(false);
    });

};


useEffect(() => {
  const formData = new FormData();
  formData.append('apiType', 'viewUsers');
  axios.post(`${server_app_api_php}/server/index.php`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  .then(response => {
    //console.log('Data Received From Server', response.data);
    //alert(response.data.message)
    if(response.data.success) {  
      setViewUsers(response.data.message);
      setTimeout(() => {
        const table = $('#tableViewUsers').DataTable({
          footerCallback: function (row, data, start, end, display) {
          },
          retrieve: true,
          pageLength: 5,
          lengthMenu: [5, 10, 25, 50, 100, 200, 500, 1000, 2000, 5000],
          initComplete: function () {
            //const api = this.api();
            //setPlotsExpensesSearchValue(api.search());
            //setPlotsExpensesEntries(api.page.len());
          }
        }); 
        //addTableButtons(table);
      }, 1000);
   
    }
    else{
      //alert(response.data.message);
      setViewUsers([]);
    }
  })
  .catch(error => {
    //alert('Error : Check Your Internet Connection Or Change Your Internet Source');
});
}, [])



useEffect(() => {
  handleRetrieveTableData ();
}, [])

const handleRetrieveTableData  = () => {
  setHandleSubmitBtn(true);
  const formData = new FormData();
  formData.append('apiType', 'viewApplicants');
  axios.post(`${server_app_api_php}/server/index.php`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  .then(response => {
    //console.log('Data Received From Server', response.data);
    //alert(response.data.message)

    if(response.data.success) {
      setViewApplicants(response.data.message);
      setTimeout(() => {
        setHandleSubmitBtn(false);

 

        const table = $('#tableViewApplicants').DataTable({
          footerCallback: function (row, data, start, end, display) {
          },
          retrieve: true,
          pageLength: 5,
          lengthMenu: [5, 10, 25, 50, 100, 200, 500, 1000, 2000, 5000],
          initComplete: function () {
            //const api = this.api();
            //setPlotsExpensesSearchValue(api.search());
            //setPlotsExpensesEntries(api.page.len());
          }
        }); 
        //addTableButtons(table);
      }, 1000);
   
    }
    else{
      //alert(response.data.message);
      setHandleSubmitBtn(false);
      setViewApplicants([]);
    }
  })
  .catch(error => {
    setHandleSubmitBtn(false);
    alert('Error : Check Your Internet Connection Or Change Your Internet Source');
});

};

   const [loading, setLoading] = useState(false);  
   const [documentURL, setDocumentURL] = useState(null);
   const [documentType, setDocumentType] = useState(null); // To store the document type
   const [fileType, setFileType] = useState(null);

   /*
const downloadAllApplicantsIDs = async () => {
  setLoading(true);

  //try {
    // Make the request to get all files as a JSON array
    //const response = await axios.get(`${server_app_api_php}/server/downloadApplicantsIDs.php`, {
      //params: { apiType: 'getApplicantsIDs' }
    //});

    const formData = new FormData();
    formData.append('apiType', 'viewApplicants');
    axios.post(`${server_app_api_php}/server/index.php`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {

    //const { files } = response.data;
//console.log('Data from the server', response.data)
    // Check if the response is successful and there are files
    const { success, message } = response.data;
    console.log('Data from the server = ', message)
    if (success && message.length > 0) {
      const mergedPdf = await PDFDocument.create();

      // Loop through each file and merge PDFs
      for (const file of message) {
        const byteCharacters = atob(file.blob); // Decode base64 content
        const byteNumbers = Array.from(byteCharacters).map(char => char.charCodeAt(0));
        const byteArray = new Uint8Array(byteNumbers);
        const pdfBlob = new Blob([byteArray], { type: file.fileType });

        const pdfBytes = await pdfBlob.arrayBuffer();
        const pdfDoc = await PDFDocument.load(pdfBytes);
        const copiedPages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
        
        copiedPages.forEach((page) => {
          mergedPdf.addPage(page);
        });
      }

      // Serialize the merged PDF document to bytes (a Uint8Array)
      const mergedPdfBytes = await mergedPdf.save();
      const mergedBlob = new Blob([mergedPdfBytes], { type: 'application/pdf' });
      const mergedFileURL = URL.createObjectURL(mergedBlob);

      // Set the merged document URL and file type
      setDocumentURL(mergedFileURL);
      setFileType('application/pdf');

    } else {
      //console.log('No documents found');
    }

    setLoading(false);

  //} catch (error) {
    //alert('Cannot Get PDF IDS, No Internet Connection, Maybe Change Your Internet Source');
    //setLoading(false);
  //}
  })
//}
  .catch(error => {
    setLoading(false);
    alert('Cannot Get PDF IDS, No Internet Connection, Maybe Change Your Internet Source');
});

};
*/


const downloadAllApplicantsIDs = async () => {
  setLoading(true);

  try {
    // Prepare the form data for the POST request
    const formData = new FormData();
    formData.append('apiType', 'getApplicantsIDs');

    // Make the POST request to download the merged PDF
    const response = await axios.post(`${server_app_api_php}/server/index.php`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'blob',  // Important: ensures the response is treated as a binary blob
    });
//console.log("Data Received from the server = ", response.data);
    // Check if the response is a PDF
    const blob = new Blob([response.data], { type: 'application/pdf' });
    const downloadUrl = URL.createObjectURL(blob);
    
    // Create a link element to initiate the download
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = 'merged_applicants_IDs.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    alert('Your Downloaded Will Start Soon');
  } catch (error) {
    //console.error('Error downloading the merged PDF:', error);
    alert('Cannot Get PDF IDS, No Internet Connection, or server issue');
  } finally {
    setLoading(false);  // Stop the loading spinner regardless of success or failure
  }
};




  const headerStyle = {
    backgroundColor: '#002D62', // Dark blue
    color: '#ffffff', // White text
    padding: '10px 20px',
    position: 'fixed',
    top: '0',
    width: '100%',
    zIndex: '1000',
  };

  const socialIconStyle = {
    marginLeft: '15px',
    color: '#ffffff',
    fontSize: '1.2em',
  };

  const userRegistrationButtonStyle = {
    backgroundColor: '#FF8800', // Dark blue
    color: '#ffffff', // White text
    borderRadius: '5px',
    fontWeight: 'bold',
    padding: '10px 20px',
    border: 'none',
  };

  // Function to handle delete
   const handleDelete = (index) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this applicant?");
    if (confirmDelete) {
     
      setHandleSubmitBtn(true);

    const formData = new FormData();
    formData.append('apiType', 'deleteApplicants');
    formData.append('registerID', index);
    axios.post(`${server_app_api_php}/server/index.php`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      //console.log('Data Received From Server', response.data);
      if(response.data.success) { 
        setHandleSubmitBtn(false);
        alert('Applicant Succefully Deleted. The Page Will Reload To Show Updated Table');
                 // Check if the table is already initialized
  //if ($.fn.DataTable.isDataTable('#tableViewApplicants')) {
    // Destroy the existing DataTable instance
    //$('#tableViewApplicants').DataTable().clear().destroy();
  //}
        //handleRetrieveTableData();
        window.location.reload();
      }
      else{
        alert(response.data.message);
        setHandleSubmitBtn(false);
      }
    })
    .catch(error => {
        alert('Error : Check Your Internet Connection Or Change Your Internet Source');
        setHandleSubmitBtn(false);
    });

    }
  };

return (
    <>
          <div hidden={!handleSubmitBtn}>
       
       <div class="loader d-flex justify-content-center">
     <div class="spinner-border red-text" style={{width: '10rem', height: '10rem'}} role="status">
         <span class=''>Loading...</span>
       </div>
     </div>
     </div>

     <div hidden={!loading}>
     <div id="overlayer"></div>
       <div class="loader d-flex justify-content-center">
     <div class="spinner-grow text-danger" style={{width: '10rem', height: '10rem'}} role="status">
         <span class=''>Loading...</span>
       </div>
     </div>
     </div>

    

         <header style={headerStyle} className=''>
        <div className="d-flex justify-content-between align-items-center">
          <div>
         
            <a href="/Login" className="text-white">
              <FontAwesomeIcon icon={faSignOutAlt} /> Logout
            </a>
          </div>
          <div>
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" style={socialIconStyle}>
            <span className='fa fa-facebook'></span>
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" style={socialIconStyle}>
            <span className='fa fa-twitter'></span>
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" style={socialIconStyle}>
              
              <span className='fa fa-instagram'></span>
            </a>
          </div>
        </div>
      </header>
    
   <main class="bg-white">  


   

{/* Modal: View And Download Applicants IDs Starts Here */}
<div className="modal fade" id="modalShowApplicantsIDs" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-full-width modal-notify modal-info" role="document"
    style={{
      maxWidth: '100%',
      width: '100%',
      margin: '0',
      padding: '0'
    }}
    >
        <div className="modal-content">
            <div className="modal-header">
                <p className="heading lead">View And Download The Applicants IDs</p>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" className="white-text">&times;</span>
                </button>
            </div>

            {loading ? (
                <>
                    <div className="spinner-grow text-danger" style={{ width: '10rem', height: '10rem' }} role="status"></div>
                </>
            ) : (
                <>
                    <div className="modal-body">
                        {/* Log the documentURL and fileType to debug 
                        {console.log('documentURL:', documentURL)}
                        {console.log('fileType:', fileType)}
                        */}

                        {documentURL ? (
                            <div style={{ marginTop: '20px' }}>
                                {fileType === 'application/pdf' ? (
                                    <iframe
                                        src={documentURL}
                                        width="100%"
                                        height="600px"
                                        title="PDF Document"
                                    ></iframe>
                                ) : (
                                    <img
                                        src={documentURL}
                                        alt="Document"
                                        style={{ width: '100%', height: 'auto' }}
                                    />
                                )}
                            </div>
                        ) : (
                            <p>No Applicants IDs found.</p>
                        )}
                    </div>
                </>
            )}

            <div className="modal-footer">
                <button
                    type="button"
                    className="btn btn-danger waves-effect ml-auto"
                    data-dismiss="modal"
                >
                    Close
                </button>
            </div>
        </div>
    </div>
</div>
{/* Modal: View And Download Applicants IDs Ends Here */}


   <hr />
    <div className="container-fluid mt-5">

       <div class="view view-cascade gradient-card-header  ocean blue-gradient">
                  <h2 class="h2-responsive mb-0 font-weight-bold white-text py-5">THE YOUTH SDG SUMMIT</h2>
                </div>

    { userType === 'Admin' && (
      <>
      <section className="fixed-sn white-skin">
            <div class="row my-5 pl-5 pr-5">

        <div className="">
              <div className="card mt-5">
                <div className="card-body">

          
                <h5 className='form-header mt-5 text-uppercase mdb-color darken-3'>REGISTER USERS
                <FontAwesomeIcon icon={faUsers} className='ml-3' />
                </h5>
                          <div className='row text-left'>
<div className='mb-3 col-md-6'>
                          <label>Name</label>
                              <MDBInput
                                label=""
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                
                            />
</div>

<div className='mb-3 col-md-6'>       
                          <label>Email</label>
                              <MDBInput
                                label=""
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                            />
                            </div>


<div className='mb-3 col-md-12'> 
<label>User Type</label>
<select
                    className="form-select"
                    name="userType"
                    value={formData.userType}
                    onChange={handleInputChange}
                    required
                    style={{ borderColor: '#002D62' }}
                  >
                    <option value="" >Select User type</option>
                    <option>User</option>
                    <option hidden = {userType != 'Admin'}>Admin</option>
                  </select>
                            </div>

                            </div>

                   

<div className='row'>
    
     <div className='col-md-12'>
             <button className="btn w-100 mb-3" onClick={handleSubmit} style={userRegistrationButtonStyle} 
                            disabled={!isFormValid() || handleSubmitBtn}
                            >Register
                            <FontAwesomeIcon icon={faUpload} className='ml-3' />
                            </button>
                </div>
                
                
                </div>

                <hr />

                <h2 className="form-header white-text ocean blue-gradient text-uppercase mt-5">Users</h2>

<table id="tableViewUsers" className="table table-striped table-bordered table-hover table-sm">
  <thead className="form-header text-uppercase ocean blue-gradient">
    <tr>
      <th>S/N</th>
      <th>Name</th>
      <th>Email</th>
      <th>User Type</th>
    </tr>
  </thead>
  <tbody>
    {viewUsers.map((val, index) => (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{val.name}</td>
        <td>{val.email}</td>
        <td>{val.userType}</td>
      </tr>
))}
  </tbody>
</table>

         
                </div>
              </div>
            </div>
          </div>
        </section>

      </>
      )}
{(<></>)}



<section className="fixed-sn white-skin">
            <div class="row my-5 pl-5 pr-5">

        <div className="">
              <div className="card mt-5">
                <div className="card-body">

                
 <span onClick={() => { downloadAllApplicantsIDs()  }}
               type='button' className='btn btn-info'  title='View And Download Applicants IDs'
              
               >Get Applicants IDs <strong class='fa fa-eye white-text'></strong></span>
<h2 className="form-header white-text ocean blue-gradient text-uppercase mt-5">Applicants</h2>

<table id="tableViewApplicants" className="table table-striped table-bordered table-hover table-sm">
  <thead className="form-header text-uppercase ocean blue-gradient">
    <tr>
    <th>Action</th>
      <th>S/N</th>
      <th>First Name</th>
      <th>Last Name</th>
      <th>Country</th>
      <th>Pronouns</th>
      <th>Email</th>
      <th>Phone</th>
      <th>Category</th>
      <th>Date</th>
    </tr>
  </thead>
  <tbody>
    {viewApplicants.map((val, index) => (
      <tr key={index}>
          <td
  title="Delete This Applicant"
  onClick={() => handleDelete(val.registerID)}
  style={{ cursor: "pointer" }}  // This makes the cursor a pointer
>
  <span className="fa fa-trash red-text"></span>
</td>
        <td>{index + 1}</td>
        <td>{val.firstName}</td>
        <td>{val.lastName}</td>
        <td>{val.country}</td>
        <td>{val.gender}</td>
        <td>{val.email}</td>
        <td>{val.phone}</td>
        <td>{val.category}</td>
        <td>{val.dateUploaded}</td>
      
      </tr>
))}
  </tbody>
</table>
                </div>
              </div>
            </div>
          </div>
        </section>

    </div>

    </main>

    </>
  );
};

export default Dashboard;
